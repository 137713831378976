import './src/styles/global.css';

export const onClientEntry = () => {
  document.querySelector('body').style.overflow = 'hidden';
  document.getElementById('page-loader-wrapper').style.display = 'block';
};

export const onRouteUpdate = () => {
  document.querySelector('.page-loader-wrapper__inner').style.opacity = '0';

  setTimeout(() => {
    document.getElementById('page-loader-wrapper').style.display = 'none';
    document.querySelector('body').style.overflow = 'auto';
  }, 410);
};
